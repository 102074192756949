<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-endereco"
    @click:outside="$emit('fechar')"
  >
    <v-card
      elevation="0"
    >
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Adicionar/Editar Endereço
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="logradouro"
              >
                <v-text-field
                  v-model="enderecoNovo.logradouro"
                  outlined
                  dense
                  hide-details="auto"
                  label="Logradouro"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="complemento"
              >
                <v-text-field
                  v-model="enderecoNovo.complemento"
                  outlined
                  dense
                  hide-details="auto"
                  label="Complemento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="numero"
              >
                <v-text-field
                  v-model="enderecoNovo.numero"
                  v-mask="'######'"
                  outlined
                  dense
                  hide-details="auto"
                  label="Número"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="cep"
              >
                <v-text-field
                  v-model="enderecoNovo.cep"
                  v-mask="'#####-###'"
                  outlined
                  dense
                  hide-details="auto"
                  label="CEP"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <autocomplete-estados
                v-model="enderecoNovo.estado_id"
              />
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="cidade_id"
              >
                <autocomplete-cidades
                  v-model="enderecoNovo.cidade_id"
                  :estado-id="enderecoNovo.estado_id"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="latitude"
              >
                <v-text-field
                  v-model="enderecoNovo.latitude"
                  outlined
                  dense
                  hide-details="auto"
                  label="Latitude"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="longitude"
              >
                <v-text-field
                  v-model="enderecoNovo.longitude"
                  outlined
                  dense
                  hide-details="auto"
                  label="Longitude"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                icon
                @click="mostrarMapa = true"
              >
                <v-icon>
                  mdi-map-marker-radius
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <dialog-mapa-selecionar-coordenada
            :mostrar="mostrarMapa"
            @coordenada-selecionada="coordenadaSelecionada"
            @fechar="mostrarMapa = false"
          />
        </validation-observer>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <btn-cancelar
          class="mr-1"
          outlined
          @click="$emit('fechar')"
        />
        <btn-salvar
          outlined
          :carregando="carregandoSalvar"
          @click="$emit('salvar', enderecoNovo)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {

    components: {
      AutocompleteEstados: () => import('@/components/input/InputAutocompleteEstados'),
      AutocompleteCidades: () => import('@/components/input/InputAutocompleteCidades'),
      DialogMapaSelecionarCoordenada: () => import('@/components/dialog/DialogMapaSelecionarCoordenada'),
    },

    props: {
      endereco: {
        type: Object,
        default: () => { return {} },
      },
      mostrar: {
        type: Boolean,
        default: false,
      },
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        mostrarMapa: false,
        enderecoNovo: {},
      }
    },

    watch: {
      validacaoFormulario (val) {
        if (this.$refs.observer) {
          this.$refs.observer.reset()
          this.$refs.observer.setErrors(val)
        }
      },

      endereco (endereco) {
        this.enderecoNovo = endereco
      },
    },

    methods: {
      coordenadaSelecionada (coordenada) {
        this.enderecoNovo = {
          ...this.enderecoNovo,
          ...{
          latitude: coordenada.lat,
          longitude: coordenada.lng,
        },
        }
        this.mostrarMapa = false
      },
    },

  }
</script>

<style lang="css">
  .dialog-endereco {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-endereco {
      width: 70% !important;
    }
  }
</style>
